<template>
  <div style="background-color: #095F96; height: 100%;">
    <!-- <a id="logo" href="https://alvarotrigo.com/fullPage/" alt="fullPage homepage">Enercred</a> -->
    <div class="text-xs-center" v-if="loading">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>
    <div id="app" v-if="!loading">
      <ul id="menu" style="background-color:#000"></ul>
      <v-container fluid grid-list-md fill-height style="padding:3%;">
        <v-layout row wrap>
          <v-flex xs12 sm12 md6 style="text-align: center">
            <div class="step2_esquerda">
              <a
                href="http://enercred.com.br"
                target="_blank"
                alt="Enercred - Energia Limpa e Barata"
              >
                <img
                  src="../../assets/logo-enercred-branco-verde.png"
                  width="250px"
                  style="padding: 5px"
                  alt="Enercred - Energia Limpa e Barata"
                  class="src"
                />
              </a>
              <div class="header hidden-sm-and-down">
                <span>
                  <b class="display-3 font-weight-black">Olá, futuro.</b>
                </span>
                <div
                  style="color:#00A8BB"
                >Finalmente você pode ter energia limpa e economizar ao mesmo tempo</div>
              </div>
            </div>
          </v-flex>
          <v-flex d-flex xs12 sm12 md6 style="text-align: center">
            <div class="form_conta">
              <div class style="padding: 50px 0px 0px 0px">
                <div class="display-1" style="padding-bottom: 15px; color: #808080">CONTA DE ENERGIA</div>precisamos de sua conta de luz atualizada para montarmos uma proposta personalizada
                <br />
                <br />
                <v-form
                  v-if="!uploadEnd"
                  ref="form"
                  v-model="valid"
                  lazy-validation
                  class="form_class"
                >
                  <!-- <div class="atencao"><b>É muito importante que a conta seja completa com todos os dados visíveis.</b></div><br> 
                  veja <a href="#" @click="dialog = true">aqui</a> um exemplo<br><br>-->
                  <div>
                    <v-alert v-model="alert" dismissible color="cyan" icon="info" outline>
                      É muito importante que a conta seja completa com todos os dados visíveis. Você pode visualizar um exemplo
                      <a href="#" @click="dialog = true">clicando aqui</a>.
                    </v-alert>
                  </div>
                  <br />
                  <br />
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="*Confirmar E-mail"
                    prepend-icon="email"
                    name="email"
                    type="email"
                    required
                  ></v-text-field>
                  <v-btn
                    block
                    color="success"
                    @click.native="selectFile"
                    v-if="!uploadEnd && !uploading"
                  >
                    <v-icon left aria-hidden="true">add_a_photo</v-icon>ADICIONAR CONTA DE ENERGIA
                  </v-btn>
                  <input
                    id="files"
                    type="file"
                    capture="camera"
                    name="file"
                    ref="uploadInput"
                    accept="image/*, application/pdf"
                    :multiple="false"
                    @change="detectFiles($event)"
                  />
                  <v-progress-circular
                    v-if="uploading && !uploadEnd"
                    :size="100"
                    :width="15"
                    :rotate="360"
                    :value="progressUpload"
                    color="success"
                  >%</v-progress-circular>
                </v-form>
                <br />
                <img
                  v-if="fileType!=='application/pdf' && uploadEnd"
                  :src="downloadURL"
                  width="50%"
                  style="align:center"
                />
                <div v-if="fileType==='application/pdf' && uploadEnd">
                  <object :data="downloadURL" type="application/pdf" width="300" height="300">
                    alt :
                    <a :href="downloadURL">{{downloadURL}}</a>
                  </object>
                </div>
                <br />
                <br />
                <div v-if="uploadEnd">
                  <v-btn flat small color="error" alt="Apagar Conta" @click="deleteImage()">
                    <v-icon dark>delete</v-icon>APAGAR
                  </v-btn>
                  <!-- <v-btn class="ma-0" dark flat color="error" @click="deleteImage()">APAGAR</v-btn> -->
                  <v-btn flat small color="success" @click="moreBills">
                    <v-icon dark alt="Adicionar nova conta">add</v-icon>MAIS CONTAS
                  </v-btn>
                  <br />
                  <br />
                  <div style="text-align: right">
                    <!-- <v-btn flat exact to="/add-power-bill" class="body-2">Adiconar mais contas</v-btn> -->
                    <v-btn :disabled="!valid" color="success" @click="next" flat large>CONTINUAR</v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
      <v-dialog
        v-model="dialog"
        max-width="80%"
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card tile>
          <v-toolbar card dark color="#808080">
            <v-btn icon dark @click="dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Exemplo de Conta de Energia</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <h4>
              Em
              <i style="color: red">vermelho</i> os principais dados utilizados para gerar a proposta. É muito importante que eles estejam nítidos.
            </h4>
            <br />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/enercred.appspot.com/o/web%2Fconta-energia-exemplo.png?alt=media&token=24a498a1-efdb-489d-bbc1-67180d2b16fc"
              width="100%"
            />
          </v-card-text>
          <div style="flex: 1 1 auto;"></div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
// import HelloWorld from '../components/HelloWorld'
import { firestorage } from "@/firebase/firestorage";
import { addLead, updateLead } from "../../services/leads";
import { enviarEmail } from "../../services/sendmail";

export default {
  data() {
    return {
      loading: true,
      email: "",
      emailRules: [
        v => !!v || "Por favor, informe o seu email",
        v => /.+@.+/.test(v) || "E-mail precisa ser válido"
      ],
      valid: false,
      progressUpload: 0,
      fileName: "",
      fileType: "",
      fileUrl: "",
      showUpload: false,
      uploadTask: "",
      uploading: false,
      uploadEnd: false,
      downloadURL: "",
      dialog: false,
      alert: true,
      lead: {}
    };
  },
  methods: {
    selectFile() {
      if (this.$refs.form.validate()) {
        this.$refs.uploadInput.click();
      }
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      });
    },
    upload(file) {
      //console.log('email: ', this.email)

      this.lead.email = this.email;
      this.fileName = file.name;
      this.fileType = file.type;
      this.fileUrl = "/leads_old/" + this.lead.email + "/" + this.fileName;
      this.uploading = true;
      // let bills = [];

      /*
      if (this.lead.bills) {
        bills = this.lead.bills;
      }
      */
      this.uploadTask = firestorage.ref(this.fileUrl).put(file);
    },
    deleteImage() {
      firestorage
        .ref(this.fileUrl)
        .delete()
        .then(() => {
          let bills = [];
          let data = {};

          if (this.lead.bills) {
            bills = this.lead.bills;
          }
          bills.pop();
          if (bills.length < 1) {
            data = { bills: bills, step: 0 };
            this.lead.step = 0;
          } else {
            data = { bills: bills };
          }

          updateLead(this.lead.email, data)
            .then(() => {
              this.lead.bills = bills;
              localStorage.setItem("lead", JSON.stringify(this.lead));
            })
            .catch(err => {
              console.error(err);
            });
          this.uploading = false;
          this.uploadEnd = false;
          this.downloadURL = "";
        })
        .catch(error => {
          console.error(`file delete error occured: ${error}`);
        });
    },
    next() {
      const data = {
        email: this.lead.email,
        name: "",
        dynamic_template_data: {
          name: ""
        },
        template_id: process.env.VUE_APP_SENDGRID_TEMPLATE_CADASTRO
      };

      enviarEmail(data)
        .then(this.$router.replace("gerar-proposta"))
        .catch(error => {
          console.error("Error: Erro ao enviar email para o usuário", error);
          this.$router.replace("gerar-proposta");
        });
    },
    moreBills() {
      (this.valid = false),
        (this.progressUpload = 0),
        (this.fileName = ""),
        (this.fileType = ""),
        (this.fileUrl = ""),
        (this.uploadTask = ""),
        (this.uploading = false),
        (this.uploadEnd = false),
        (this.downloadURL = ""),
        this.$router.replace("cadastro-conta-energia");
    }
  },
  mounted() {
    // Verifica se o parametro email foi passado na URL
    // console.log(this.$route.query.email)
    if (this.$route.query.email) {
      // Caso tenha passado email no parametro seta email do lead
      const emailtmp = this.$route.query.email;
      this.lead.email = emailtmp;
      this.email = emailtmp;
      this.loading = false;
    } else {
      this.loading = false;
    }
  },
  watch: {
    uploadTask: function() {
      this.uploadTask.on(
        "state_changed",
        sp => {
          this.progressUpload = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            // Adiciona o endereço do arquivo no banco de dados de lead
            let bills = [];

            // console.log('tipo do arquivo: ', this.fileType);
            if (this.lead.bills) {
              bills = this.lead.bills;
            }

            let bill = {
              url: downloadURL,
              type: this.fileType
            };
            bills.push(bill);
            let data = {
              customer_id: this.email,
              email: this.email,
              bills: bills,
              step: 1
            };

            addLead(data)
              .then(() => {
                this.lead.bills = bills;
                this.lead.step = 1;
                localStorage.setItem("lead", JSON.stringify(this.lead));
              })
              .catch(err => {
                console.error(err);
              });

            this.uploadEnd = true;
            this.downloadURL = downloadURL;
            this.$emit("downloadURL", downloadURL);
          });
        }
      );
    }
  }
};
</script>

<style scoped>
@charset "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}

fieldset,
img {
  border: 0;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
caption,
th {
  text-align: left;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
}
* {
  -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box; /* <=28 */
  box-sizing: border-box;
}

/* Custom
 * --------------------------------------- */
html,
body {
  height: 100%;
  background-color: #095f96;
}
template {
  background-color: #095f96;
}
.area {
  height: 100px;
  width: 100px;
  background: red;
  margin: 10px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.content {
  height: 100px;
  width: 100px;
  display: table-cell;
  vertical-align: middle;
}
.header {
  font-size: 2em;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  display: table-cell;
  color: #fff;
}
.header span {
  font-size: 2em;
  color: #fff;
  font-weight: bold;
}
.header br {
  font-size: 4em;
  color: #fff;
  font-weight: bold;
}
.form_conta {
  height: 100%;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  background-color: #fff;
  padding: 10% 5% 2% 5%;
  text-align: center;
  border-radius: 10px;
}
.step2_esquerda {
  width: 95%;
  padding-left: 5%;
  padding-top: 10%;
  text-align: left;
}
/*
.form_class {
  padding-top: 100px;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  text-align: center;
}
*/
.section {
  position: relative;
  text-align: center;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 900;
}
#section-1 h1 {
  font-size: 2em;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-1 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-1-button {
  padding: 0.93em 1.87em;
  background: #00a8bb;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 500;
}
#section-3 h1 {
  font-size: 2em;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-3 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-3-button {
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-3 .intro {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  width: 50%;
  padding: 0 3em;
}

h3 {
  font-size: 4em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
h2 {
  font-size: 2em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
#logo {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #fff;
  font-weight: bold;
  z-index: 99;
  font-size: 1.9em;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}
.progress-bar {
  margin: 10px 0;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.atencao {
  font-size: 1.5em;
  color: #00a8bb;
  font-weight: bold;
}

/* Menu
 * --------------------------------------- */
#menu-line {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 159px;
  height: 2px;
  background: #fff;
}

#menu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 70;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  letter-spacing: 1px;
  font-size: 1.1em;
}
#menu li {
  display: inline-block;
  margin: 10px 0px;
  position: relative;
}
#menu a {
  color: #fff;
  padding: 0 1.1em 1.1em 1.1em;
}
#menu li.active a:after {
  content: "";
  margin: 0 1.1em 0 1.1em;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  display: block;
}

/* Actions buttons
 * --------------------------------------- */
.actions {
  position: fixed;
  bottom: 2%;
  margin: 0 auto;
  z-index: 99;
  left: 0;
  right: 0;
  text-align: center;
}
.actions li {
  display: inline-block;
  margin: 0.3em 0.3em;
}
.actions-button {
  padding: 0.73em 1.47em;
  background: rgba(53, 73, 94, 0.47);
  border-radius: 5px;
  display: block;
  color: #fff;
  cursor: pointer;
}

/* Twitter
 * --------------------------------------- */
.twitter-share i {
  vertical-align: middle;
  position: relative;
  top: 2px;
  display: inline-block;
  width: 38px;
  height: 14px;
  color: white;
  top: -4px;
  left: -2px;
  fill: #fff;
}
.twitter-share svg {
  height: 40px;
  margin-top: -10px;
}
.view-github {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 999;
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}

.shell {
  width: calc(50% - 0px);
  display: inline-block;
  background-color: #fff;
  /*  background-image: url('../assets/eolica.png'); */
  background-position: center;
  border-radius: 0px 0px 0 0;
  text-align: left;
  margin-right: 0px;
}
</style>

